/* eslint-disable no-shadow */
export const state = () => ({
  showSidebar: false,
  sidebarExpanded: null,
  schoolWebsiteActiveNavSlugs: [],
  studentProfilesFilters: {
    page: 1,
    search: '',
    group_ids: [],
    role: 'current',
    usi: null,
  },
  pspTeacherReportTableFilters: {
    page: 1,
    status: null,
    search: '',
    group_ids: [],
    role: 'current',
    usi: null,
    careerPassInProgress: null,
  },
  courseSearchFilters: {
    page: 1,
    keyword: '',
    first: 20,
    duration: null,
    state: '',
    study_area: null,
    part_time: false,
    shortlist: false,
    atar: null,
    active_course: false,
    delivery_mode: '',
    qualification_level: '',
    institution_type: '',
    institution: '',
  },
  providerSearchFilters: {
    page: 1,
    keyword: '',
    first: 20,
  },
  studentPortfolioFilters: { type: null },
  studentSearchFilters: { search: '' },
  occupationsFilters: { page: 1, search: '' },
  studentGroupsFilters: { page: 1, search: '' },
  teachersFilters: { page: 1, search: '', role: null },
  studentsAchieveWorksFilters: { page: 1, search: '' },
  destinationSurveyFilters: {
    page: 1,
    search: '',
    class_year: new Date().getFullYear(),
    status: null,
    notification_status: null,
  },
  careerPlanFilters: {
    page: 1,
    search: '',
    status: null,
    group_ids: [],
    role: 'current',
  },
  careerPortfolioFilters: {
    page: 1,
    search: '',
    status: null,
    group_ids: [],
    activity_id: null,
    role: 'current',
  },
  careerInvestigationFilters: {
    page: 1,
    search: '',
    status: null,
    group_ids: [],
    role: 'current',
  },
  enterpriseSkillsAuditFilters: {
    page: 1,
    search: '',
    status: null,
    group_ids: [],
    role: 'current',
  },
  studentInteractionsFilters: {
    page: 1,
    search: '',
    group_ids: [],
    role: 'current',
  },
  personalStatementFilters: {
    page: 1,
    search: '',
    status: null,
    group_ids: [],
    role: 'current',
  },
  assignmentsFilters: {
    page: 1,
    search: '',
    status: null,
    sortBy: null,
    sortDirection: 'desc',
  },
});

export const mutations = {
  setSchoolWebsiteActiveNavSlugs: (state, newSlugs) => {
    state.schoolWebsiteActiveNavSlugs = newSlugs;
  },
  showSidebar: (state) => {
    state.showSidebar = true;
  },
  hideSidebar: (state) => {
    state.showSidebar = false;
  },
  setSidebarExpanded: (state, value) => {
    state.sidebarExpanded = value;
  },
  setStudentProfilesFilters: (state, newFilters) => {
    state.studentProfilesFilters = newFilters;
  },
  setStudentPortfolioFilters: (state, newFilters) => {
    state.studentPortfolioFilters = newFilters;
  },
  setStudentSearchFilters: (state, newFilters) => {
    state.studentSearchFilters = newFilters;
  },
  setOccupationsFilters: (state, newFilters) => {
    state.occupationsFilters = newFilters;
  },
  setStudentGroupsFilters: (state, newFilters) => {
    state.studentGroupsFilters = newFilters;
  },
  setDestinationSurveyFilters: (state, newFilters) => {
    state.destinationSurveyFilters = newFilters;
  },
  setCareerPlanFilters: (state, newFilters) => {
    state.careerPlanFilters = newFilters;
  },
  setCareerPortfolioFilters: (state, newFilters) => {
    state.careerPortfolioFilters = newFilters;
  },
  setCareerInvestigationFilters: (state, newFilters) => {
    state.careerInvestigationFilters = newFilters;
  },
  setEnterpriseSkillsAuditFilters: (state, newFilters) => {
    state.enterpriseSkillsAuditFilters = newFilters;
  },
  setStudentInteractionsFilters: (state, newFilters) => {
    state.studentInteractionsFilters = newFilters;
  },
  setPersonalStatementFilters: (state, newFilters) => {
    state.personalStatementFilters = newFilters;
  },
  setTeachersFilters: (state, newFilters) => {
    state.teachersFilters = newFilters;
  },
  setStudentsAchieveWorksFilters: (state, newFilters) => {
    state.studentsAchieveWorksFilters = newFilters;
  },
  setJourneyOverviewFilters: (state, newFilters) => {
    state.journeyOverviewFilters = newFilters;
  },
  setCourseSearchFilters: (state, newFilters) => {
    state.courseSearchFilters = newFilters;
  },
  setProviderSearchFilters: (state, newFilters) => {
    state.providerSearchFilters = newFilters;
  },
  setAssignmentsFilters: (state, newFilters) => {
    state.assignmentsFilters = newFilters;
  },
  setPspTeacherReportTableFilters: (state, newFilters) => {
    state.pspTeacherReportTableFilters = newFilters;
  },
};

export const getters = {};

export const actions = {
  toggleSidebar({ commit, state }) {
    if (state.showSidebar) {
      commit('hideSidebar');
    } else {
      commit('showSidebar');
    }
  },
};
