import dayjs from '@/utils/dayjs';
import gql from 'graphql-tag';
import { BASE_AUTH_USER_FIELDS } from '@/graphql/fragments/authUser';

const GET_SELF_QUERY = gql`
  ${BASE_AUTH_USER_FIELDS}
  query self {
    self {
      ...BaseAuthUserFields
    }
    currentSchool {
      allGroups {
        id
        name
        class_year
      }
      enabledPrograms {
        id
        name
        slug
        order
      }
      enabledActivities {
        id
        name
        slug
        type
        parent_id
        program_id
        meta
        order
        current_school_settings {
          scorm_file_path
          lesson_outline_file_path
          curriculum_mapping_file_path
        }
      }
      school_cluster {
        id
        cluster_toolbox_entries {
          id
          title
          url
        }
      }
      schoolYearDisabledComponents {
        id
        year
        component_type
        component_id
      }
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!, $schoolId: ID!, $recaptchaToken: String!) {
    login(
      email: $email
      password: $password
      school_id: $schoolId
      recaptcha_token: $recaptchaToken
    ) {
      user_id
      role_type
    }
  }
`;

const LOGOUT_MUTATION = gql`
  mutation logout {
    logout
  }
`;

export default class CareertoolsScheme {
  constructor($auth, options) {
    this.$graphql = $auth.ctx.app.$graphql;
    this.$cookies = $auth.ctx.app.$cookies;
    this.$config = $auth.ctx.app.$config;
    this.store = $auth.ctx.app.store;
    this.$auth = $auth;
    this.name = options.name;
    this.app = $auth.ctx.app;

    this.options = {
      ...options,
      redirect: {
        login: '/login',
        logout: '/',
        callback: '/login',
        home: '/dashboard',
      },
    };
  }

  check() {
    const expires = this.$cookies.get('auth.expires');

    if (expires && dayjs().unix() < expires) {
      return { valid: true };
    }

    this.$auth.reset();
    return { valid: false };
  }

  async mounted() {
    if (process.server) {
      await this.$auth.fetchUserOnce();
    }
  }

  async login(params) {
    this.$auth.reset();

    const res = await this.app.apolloProvider.defaultClient.mutate({
      mutation: LOGIN_MUTATION,
      variables: params,
    });

    if (res.data.login) {
      this.$cookies.set(
        'auth.expires',
        res.data.login.role_type === 'student'
          ? dayjs().unix() + this.store.state.expireTimeStudent
          : dayjs().unix() + this.store.state.expireTimeTeacher,
        { domain: this.$config.cookieDomain, path: '/' },
      );

      this.$cookies.set('auth.role_type', res.data.login.role_type, {
        domain: this.$config.cookieDomain,
        path: '/',
      });

      this.$cookies.set('auth.user_id', res.data.login.user_id, {
        domain: this.$config.cookieDomain,
        path: '/',
      });

      return this.$auth.fetchUser();
    }

    return false;
  }

  async fetchUser() {
    if (!this.check().valid) {
      return false;
    }

    try {
      const res = await this.app.apolloProvider.defaultClient.query({
        query: GET_SELF_QUERY,
        fetchPolicy: 'no-cache',
      });

      const authUserIdCookie = this.$cookies.get('auth.user_id');
      if (authUserIdCookie !== parseInt(res.data.self.id, 10)) {
        this.$cookies.set('auth.user_id', res.data.self.id, {
          domain: this.$config.cookieDomain,
          path: '/',
        });
      }

      this.store.commit('setActivities', res.data.currentSchool.enabledActivities);
      this.store.commit('setPrograms', res.data.currentSchool.enabledPrograms);
      this.store.commit('setGroups', res.data.currentSchool.allGroups);
      this.store.commit(
        'setSchoolYearDisabledComponents',
        res.data.currentSchool.schoolYearDisabledComponents,
      );
      this.store.commit('setFavouriteGroups', res.data.self.currentSchoolFavouriteGroups);
      this.store.commit(
        'setClusterToolboxEntries',
        res.data.currentSchool.school_cluster?.cluster_toolbox_entries || [],
      );
      this.$auth.setUser(res.data.self);
      return true;
    } catch (e) {
      this.$auth.reset();
      this.$auth.callOnError(e, { method: 'fetchUser' });
      return false;
    }
  }

  async logout() {
    await this.app.apolloProvider.defaultClient.mutate({
      mutation: LOGOUT_MUTATION,
    });

    this.app.store.commit('braze/setContentCards', []);
    this.app.store.commit('learnerProfile/resetLearnerProfile');

    return this.$auth.reset();
  }

  reset() {
    this.$cookies.remove('auth.expires', {
      domain: this.$config.cookieDomain,
      path: '/',
    });
    this.$cookies.remove('auth.role_type', { domain: this.$config.cookieDomain, path: '/' });
    this.$cookies.remove('auth.impersonate.return_path', {
      domain: this.$config.cookieDomain,
      path: '/',
    });
    this.$cookies.remove('auth.impersonate.role_type', {
      domain: this.$config.cookieDomain,
      path: '/',
    });
    this.$cookies.remove('auth.is_impersonating', { domain: this.$config.cookieDomain, path: '/' });
    this.$cookies.remove('auth.impersonate.impersonator_id', {
      domain: this.$config.cookieDomain,
      path: '/',
    });
    this.$cookies.remove('auth.impersonate.user_id', {
      domain: this.$config.cookieDomain,
      path: '/',
    });
    this.$cookies.remove('auth.unsafe_token_login', {
      domain: this.$config.cookieDomain,
      path: '/',
    });

    this.$cookies.remove('auth.user_id', { domain: this.$config.cookieDomain, path: '/' });

    this.$auth.isImpersonating = false;

    this.$auth.setUser(false);
  }
}
