<template>
  <component
    :is="to ? (isExternal ? 'a' : 'NuxtLink') : 'button'"
    :to="!isExternal && to"
    v-bind:[hrefAttribute]="to"
    :target="isExternal && openNewTab && '_blank'"
    :aria-disabled="buttonIsDisabled"
    :class="{
      btn: true,
      disabled: buttonIsDisabled,
      'w-100': fullWidth,
      [`btn-${size}`]: true,
      [`btn-${variant}`]: true,
      [`btn-${computedUserType}`]: true,
      ['is-external']: isExternal,
      'flex-row-reverse': icon && icon.position === 'right',
    }"
    @click="click"
    :type="to ? false : type"
    @keydown.tab="$emit('tab', $event)"
    @focus="$emit('focus', $event)"
    :aria-label="ariaLabel"
    :role="isRoleLink ? 'link' : false"
    class="d-flex align-items-center justify-content-center"
    :disabled="buttonIsDisabled"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
    @mouseover.native="handleMouseOver"
    @mouseleave.native="handleMouseLeave"
  >
    <IconDefault
      v-if="icon"
      :width="iconWidth"
      :path="iconHover && buttonIsHovered ? iconHover.path : icon.path"
      :class="{ 'me-2': icon.position === 'left', 'ms-2': icon.position === 'right' }"
    />
    <slot></slot>
    <span v-if="loading" class="spinner-border spinner-border-sm ms-1" role="status"></span>
  </component>
</template>

<script>
import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import IconDefault from '@/components/icon/Default';
import useAuth from '@/hooks/auth';

export default defineComponent({
  components: {
    IconDefault,
  },
  props: {
    userType: {
      type: String,
      required: false,
      validator: (v) =>
        ['student', 'teacher', 'school-theme', 'black', 'white', 'primary', 'business'].indexOf(
          v,
        ) !== -1,
    },
    variant: {
      type: String,
      required: true,
      validator: (v) =>
        [
          'simple',
          'filled',
          'filled-solid',
          'outlined-white',
          'outlined',
          'underlined',
          'link',
          'link-white',
          'no-style',
        ].indexOf(v) !== -1,
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: (v) => ['sm', 'md', 'lg', 'xl'].indexOf(v) !== -1,
    },
    icon: {
      type: Object,
      required: false,
    },
    iconHover: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: [String, Object],
      required: false,
    },
    isExternal: {
      type: Boolean,
      required: false,
    },
    openNewTab: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    ariaLabel: {
      required: false,
      type: String,
    },
    isRoleLink: {
      type: Boolean,
      required: false,
    },
    customIconWidth: {
      type: Number,
      required: false,
    },
  },
  setup(props, { emit }) {
    const buttonIsDisabled = computed(() => props.disabled || props.loading);
    const buttonIsHovered = ref(false);
    const { user } = useAuth();

    const handleMouseOver = () => {
      buttonIsHovered.value = true;
    };

    const handleMouseLeave = () => {
      buttonIsHovered.value = false;
    };

    const computedUserType = computed(() => props.userType || user.value?.role_type || 'teacher');

    const click = (e) => {
      if (buttonIsDisabled.value) {
        e.preventDefault();
        return;
      }

      emit('click', e);
    };

    const iconWidth = computed(() => {
      if (props.customIconWidth) {
        return props.customIconWidth;
      }

      if (props.size === 'md' || props.size === 'lg') {
        return 25;
      }

      return 15;
    });

    const hrefAttribute = computed(() => (props.isExternal && props.to ? 'href' : ''));

    return {
      click,
      hrefAttribute,
      buttonIsDisabled,
      buttonIsHovered,
      iconWidth,
      computedUserType,
      handleMouseOver,
      handleMouseLeave,
    };
  },
});
</script>

<style lang="scss" scoped>
// shared across all buttons
.btn {
  gap: 8px;
  width: fit-content;
  border-radius: 6px;
  border: none;
  font-weight: 700;
  padding: 1px 1px;
  line-height: 1.2;
  height: fit-content;
  // Fixes Safari bug causing css issues
  -webkit-appearance: unset;

  &.btn-no-style {
    background: none;
    border: none;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    text-align: inherit;
    line-height: inherit;
    border-radius: inherit;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
}

// sizing
.btn-sm {
  padding: 5px 10px;
  font-size: 11px;
  line-height: 12px;
}

.btn-md {
  padding: 8px 16px;
  font-size: 12px;
  line-height: 16px;
}

.btn-lg {
  padding: 14px 24px;
  font-size: 16px;
  line-height: 24px;
}

.btn-xl {
  padding: 16px 32px;
  font-size: 18px;
  line-height: 24px;
}

// Variants
.btn-simple {
  color: $primary-1;
}

// variants + user types
.btn-filled {
  color: $white;
  background: $button-bg;
  border: 1px solid $button-bg;

  &:hover {
    border: 1px solid $button-bg-alt;
    background: $button-bg-alt;
    color: $white;
  }

  &.btn-sm:focus {
    box-shadow: 0 0 0 3px #bfbefc;
  }
  &.btn-md:focus {
    box-shadow: 0 0 0 4px #bfbefc;
  }
  &.btn-lg:focus {
    box-shadow: 0 0 0 6px #bfbefc;
  }
  &.btn-xl:focus {
    box-shadow: 0 0 0 8px #bfbefc;
  }

  &.btn-business {
    background: #2462d2;
    border-color: #2462d2;

    &:hover {
      background: #296bde;
      color: $white;
    }

    &:focus {
      box-shadow: 0 0 0 toRem(4) rgba(62, 164, 240, 0.2);
    }
  }

  &.btn-school-theme {
    border-radius: 10px;

    background: $school-theme-color;
    border-color: $school-theme-color;

    &:hover {
      background: $school-theme-color-darker;
      color: white;
    }

    &.btn-sm:focus {
      box-shadow: 0 0 0 3px $school-theme-color-lighter;
    }
    &.btn-md:focus {
      box-shadow: 0 0 0 4px $school-theme-color-lighter;
    }
    &.btn-lg:focus {
      box-shadow: 0 0 0 6px $school-theme-color-lighter;
    }
    &.btn-xl:focus {
      box-shadow: 0 0 0 8px $school-theme-color-lighter;
    }
  }

  &.btn-black {
    border-radius: 10px;
    color: white;
    background: black;
    border-color: black;

    &:hover {
      color: white;
      background: black;
    }

    &:focus {
      box-shadow: 0 0 0 toRem(4) rgba(0, 0, 0, 0.2);
    }
  }

  &.btn-white {
    border-radius: 10px;
    color: black;
    background: white;
    border-color: white;
    &:hover {
      color: black;
      background: white;
    }

    &:focus {
      box-shadow: 0 0 0 toRem(4) rgba(234, 37, 142, 0.2);
    }
  }
}

.btn-filled-solid {
  color: $white;
  background-color: $primary-1;
  border: 1px solid $primary-1;
  border-radius: 10px;

  &:focus,
  &:hover {
    box-shadow: 0 0 0 toRem(4) rgba(62, 164, 240, 0.2);
  }
}

.btn-outlined-white {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;

  &:hover {
    color: $cerise;
    border-color: $cerise;
  }
}

.btn-outlined {
  background: transparent;

  border: 1px solid $button-bg;
  color: $button-bg;

  &:hover {
    background: $button-bg;
    color: $white;
  }

  &.btn-sm:focus {
    box-shadow: 0 0 0 3px #bfbefc;
  }
  &.btn-md:focus {
    box-shadow: 0 0 0 4px #bfbefc;
  }
  &.btn-lg:focus {
    box-shadow: 0 0 0 6px #bfbefc;
  }
  &.btn-xl:focus {
    box-shadow: 0 0 0 8px #bfbefc;
  }

  .spinner-border {
    color: $button-bg;
  }

  &.btn-school-theme {
    border-color: $school-theme-color;
    color: $school-theme-color;

    &:hover {
      background: $school-theme-color;
      color: $white;
    }
  }

  &.btn-business {
    border: 1px solid $primary;
    color: $primary;

    &:hover {
      background: $primary;
      color: $white;
    }

    &:focus {
      box-shadow: 0 0 0 toRem(4) rgba(62, 164, 240, 0.2);
    }

    &.selected {
      background: $primary;
      color: $white;
    }

    .spinner-border {
      color: #2462d2;
    }
  }

  &.btn-primary {
    border-color: $primary;
    color: $primary;

    &:hover {
      background: $primary-1;
      color: $white;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.btn-underlined {
  border-radius: 0;
  color: $primary-1;
  border: 1px solid transparent;

  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
    height: 3px;
    border-radius: 1.5px;
    background-color: $button-bg;
  }
  &:hover::after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
    height: 3px;
    border-radius: 1.5px;
    background-color: $button-bg-alt;
  }

  &:focus {
    box-shadow: 0 0 0 toRem(4) #bfbefc;
  }

  &.btn-business {
    background-color: transparent;

    &:hover {
      color: $royal-blue;
      border: 1px solid transparent;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      display: block;
      height: 3px;
      border-radius: 1.5px;
      background-color: #2462d2; /* royal-blue */
    }
    &:hover::after {
      content: '';
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      display: block;
      height: 3px;
      border-radius: 1.5px;
      background-color: #296bde; /* darker-royal-blue */
    }

    &:focus {
      color: $royal-blue;
      border: 1px solid transparent;
      box-shadow: 0 0 0 toRem(2) rgba(62, 164, 240, 0.2);
    }
  }
}

.btn-link {
  border-radius: 0;
  font-weight: 400;
  text-decoration: none;
  padding: 0 0;
  border: 1px solid transparent;
  border-bottom: 1px solid $button-bg;
  color: $button-bg;

  &:hover {
    border-color: transparent;
  }

  &.btn-sm:focus {
    box-shadow: 0 0 0 3px #bfbefc;
  }
  &.btn-md:focus {
    box-shadow: 0 0 0 4px #bfbefc;
  }
  &.btn-lg:focus {
    box-shadow: 0 0 0 6px #bfbefc;
  }
  &.btn-xl:focus {
    box-shadow: 0 0 0 8px #bfbefc;
  }

  &.btn-business {
    border-bottom: 1px solid #2462d2;
    color: #2462d2;

    &:hover {
      border-color: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 toRem(2) rgba(62, 164, 240, 0.2);
    }
  }
}

.btn-link-white {
  border-radius: 0;
  font-weight: 400;
  text-decoration: none;
  color: white;
  border: 1px solid transparent;
  border-bottom: 1px solid white;
  padding: 0 0;

  &:hover {
    border-color: transparent;
    color: $button-bg-alt;
  }

  &.btn-business {
    &:hover {
      border-color: transparent;
      color: #2462d2;
    }
  }
}

// TODO:: Can remove this style if all buttons on business site use this component
.business-page {
  .btn {
    border-radius: 20px;
  }
}
</style>
